export const isActiveDataAttribute = (
  element: HTMLElement,
  name: string,
  value: string
) => element.dataset[name] === value;

export const toggleDataAttribute = (
  element: HTMLElement,
  name: string,
  value: string
) => {
  if (!isActiveDataAttribute(element, name, value)) {
    element.dataset[name] = value;
  } else {
    delete element.dataset[name];
  }
};
